<template>
    <o-data-lookup :data-object="dsLookupDataObject" :whereClause="`DescendantOrgUnit_ID = ${(OrgUnitId?OrgUnitId:context.id)}`" reloadOnWhereClauseChange>
        <template #target="{ target }">
            <component v-if="is" :is="is" :ref="target" v-bind="$attrs" :value="textInputValue"></component>
            <input v-else-if="textInput" :ref="target" v-bind="$attrs" :value="textInputValue" readonly>
            <span v-else :ref="target" style="cursor:pointer;">    
                <slot name="slotArea"></slot>            
            </span>
        </template>

        <o-column field="ID" width="80"></o-column>
        <o-column field="Name" width="150"></o-column>
        <o-column field="Title" width="300"></o-column> 
        <o-column field="OrgUnit" width="200"></o-column>
    </o-data-lookup>
</template>

<script setup>
    import { getOrCreateDataObject } from 'o365.vue.ts'
    import context from "o365.modules.Context.ts";

    const props = defineProps({
        is: String,
        OrgUnitId: Number,    
        textInputValue: String,
        textInput: Boolean
    });

    const dsLookupDataObject = getOrCreateDataObject({
        id: 'dsLookupDataObject' + crypto.randomUUID(),
        viewName: 'aviw_Scope_PackagesLookup',
        maxRecords: 25,
        whereClause: "",
        loadRecents: false,
        distinctRows: true,
        fields:
             [{name: "ID", type: "number" },
             {name: "Name", type: "string" , sortOrder: 1, sortDirection: "asc" },             
             {name: "NameAndTitle", type: "string" },             
             {name: "Title", type: "string" },             
             {name: "OrgUnit", type: "string" }]
    });

</script>